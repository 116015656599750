/* Variables overrides */
$primary: #F04E7C;
$secondary: #3d3935;
$tertiary: #333;
$border-color: #ebebea;

$body-color: #777471;
$headings-color: $secondary;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$search-home-bg-color: $secondary;
$search-overview-bg-color: $secondary;

$navbar-dark-color: $primary;
$navbar-dark-active-color: $tertiary;
$navbar-dark-hover-color: $tertiary;

$border-radius: 4px;

$call_to_action_bg: $primary !default;
$call_to_action_text: white;
$spinner-color: #ddd;
$step-background:$gray-200;

$footer-background-color: $secondary !default;

@import url('https://fonts.googleapis.com/css?family=Montserrat:wght@500&display=swap');

$font-family-base: "Montserrat",sans-serif,Arial,Helvetica,"Nimbus Sans L";
$font-family-sans-serif: $font-family-base;
$btn-font-family: $font-family-base;
$headings-font-family: $font-family-base;

/* Flean base imports */
@import "../../flean_base_2019/sass/imports";

#header > .header.header-top-strip .main .container .logo img{
  max-width: 300px;
  max-height:60px;
  margin:1rem 0;
}
